import React from "react"
import kebabCase from "lodash.kebabcase"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ConcatWords from "../utils/ConcatWords"
import formatDate from "../utils/formatDate"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

const BlogPost = ({ data, pageContext }) => {
  const { mdx } = data
  const { prev, next } = pageContext
  const imageSource = mdx.frontmatter.image
    ? mdx.frontmatter.image.childImageSharp.fluid.src
    : ""
  const mainCategory = mdx.frontmatter.category[0]
  const mdxComponents = {
    Link,
  }

  return (
    <Layout>
      <Seo title={mdx.frontmatter.title} />

      <div className="blog-post">
        <header className="blog-post__headings pt-8 md:pt-12 pb-8">
          <div className="blog-post__mainCategory">
            <Link to={`/blog/category/${kebabCase(mainCategory)}`}>
              {mainCategory}
            </Link>
          </div>
          <h1 className="blog-post__mainHeading my-0 text-3xl md:text-5xl">
            {mdx.frontmatter.title}
          </h1>
          {mdx.frontmatter.subtitle && (
            <h2 className="my-0 text-xl text-gray-400 md:text-2xl">
              {mdx.frontmatter.subtitle}
            </h2>
          )}
        </header>

        {imageSource && (
          <div className="full-width">
            <div className="blog-post__mainImage">
              <img
                className="mx-auto"
                src={imageSource}
                alt={mdx.frontmatter.title}
              />
            </div>
          </div>
        )}

        <div className="blog-post__content font-serif mb-16">
          <MDXProvider components={mdxComponents}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>

        <div className="blog-post__footer">
          <div>
            {mdx.frontmatter.category.map((cat, index, arr) => (
              <ConcatWords arrCount={arr.length} index={index} key={cat}>
                <Link to={`/blog/category/${kebabCase(cat)}`}>{cat}</Link>
              </ConcatWords>
            ))}
          </div>

          <div className="mt-2 text-gray-500">
            {formatDate(mdx.frontmatter.date)}
          </div>

          <div className="mt-8 md:flex">
            {prev && (
              <div className="mb-4 md:mb-0 text-center">
                <Link to={prev.node.fields.slug}>
                  <div>
                    <div>
                      {" "}
                      {"<"} {prev.node.frontmatter.title}
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <span className="flex-grow hidden md:block" />

            {next && (
              <div className="text-center">
                <Link to={next.node.fields.slug}>
                  <div>
                    <div>
                      {" "}
                      {next.node.frontmatter.title} {">"}
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        category
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              src
            }
          }
        }
      }
    }
  }
`
